<template>
  <v-container fluid>
    <v-sheet :width="$vuetify.breakpoint.mobile ? '92vw' : '80vw'" class="mx-auto">
      <v-container fluid class="navbar d-flex flex-row">
        <v-row class="align-center justify-center" no-gutters>
          <v-col cols="auto" class="mr-auto">
            <div class="text-h6 font-weight-regular">Elever</div>
          </v-col>
          <v-col cols="4" class="mx-auto">
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                clearable
                label="Search"
                single-line
                outlined
                dense
                hide-details=""
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="auto" class="ml-auto d-flex flex-row align-center">
            <v-switch v-model="showAllStudents" label="Vis afsluttede elever" />
            <r-btn class="ml-2" v-if="hasAccess('ModifyStudents')" :action="true" @click="addUser">Tilføj elev</r-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table :items="userList" :search="search" :headers="dataTableHeaders" @click:row="userDetails" v-bind="$util.dataTableOptions()">
        <template #[`item.disabled`]="{ item }">
          <readonly-checkbox :value="!item.disabled" />
        </template>
        <template #[`item.actions`]="{ item }" class="d-flex">
          <div class="d-flex justify-end" v-if="hasAccess('ModifyStudents')">
            <r-btn icon title="Rediger" @click.stop="editUser(item)">
              <v-icon>mdi-pencil</v-icon>
            </r-btn>
            <r-btn icon @click.stop="userDetails(item)" title="Detaljer">
              <v-icon>mdi-dots-horizontal</v-icon>
            </r-btn>
          </div>
        </template>
      </v-data-table>
      <create-edit-user ref="CreateEditUserRef" @user-updated="getStudentList" />
    </v-sheet>
  </v-container>
</template>

<script>
import apiService from '@/services/apiService';
import CreateEditUser from '@/components/dialogs/CreateEditUser.vue';
import ReadonlyCheckbox from '@/components/ReadonlyCheckbox.vue';
import { mapGetters } from 'vuex';

export default {
  components: { CreateEditUser, ReadonlyCheckbox },
  computed: {
    ...mapGetters(['user', 'currentOrgId', 'hasAccess', 'isAdmin']),
    showAllStudents: {
      get() {
        return this.showAll;
      },
      set() {
        this.showAll = !this.showAll;
      },
    },
    dataTableHeaders() {
      const headers = [
        {
          text: 'Navn',
          value: 'name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Tlf. Nummer',
          value: 'phoneNumber',
        },
      ];

      if (this.isAdmin) {
        headers.push({
          text: 'Kørelærer',
          value: 'staffName',
        });
        headers.push({
          text: 'Køreskole',
          value: 'organizationName',
        });
      }

      headers.push({
        text: '',
        value: 'actions',
      });

      return headers;
    },
  },
  watch: {
    showAll: {
      immediate: true,
      handler() {
        this.getStudentList();
      },
    },
    currentOrgId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getStudentList();
        }
      },
    },
  },
  data: () => ({
    showAll: false,
    search: '',
    userList: [],
    isFetching: false,
  }),
  methods: {
    async getStudentList() {
      this.isFetching = true;
      this.userList = await apiService.getStudentList(this.currentOrgId, this.showAll);
      this.isFetching = false;
    },
    addUser() {
      this.$refs.CreateEditUserRef.createUser();
    },
    editUser(user) {
      this.$refs.CreateEditUserRef.editUser(user.id, false);
    },
    userDetails(user) {
      this.$router.push({ name: 'StudentDetails', params: { UserId: user.id } });
    },
  },
  created() {
    this.getStudentList();
  },
};
</script>

<style></style>
